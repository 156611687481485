@import '../../../assets/styles/variables';
@import '../../../assets/styles/mixins';

.application-page {
  $this: &;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  min-height: 100vh;
  padding-top: $header-height;
  &--with-next-step {
    #{$this}__content {
      min-height: calc(100% - #{$footer-height} - #{$nextStepBlock-height});
    }
  }
  &__content {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    flex: 1;
    height: calc(100% - #{$footer-height});
    @include desktop {
      padding: 2rem;
    }
  }
  &__next-step-block {
    display: flex;
    justify-content: center;
    align-items: center;
    height: $nextStepBlock-height;
    width: 100%;
    background-color: $backgroundBlue;
    @include desktop {
      justify-content: flex-end;
    }
  }
  &__next-step-button {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 6.3rem;

    @include desktop {
      margin-right: 6.5rem;
    }
  }
  &__modal-buttons-container {
    margin-top: 2rem;
    @include desktop {
      display: flex;
      justify-content: center;
    }
    & button:first-child {
      margin-bottom: 1rem;
      @include desktop {
        margin-bottom: 0;
        margin-right: 1rem;
      }
    }
  }
}

.arrow-right {
  width: 0;
  height: 0;
  border-top: 7px solid transparent;
  border-bottom: 7px solid transparent;
  border-left: 7px solid $white;
  margin-left: 0.5rem;
}
