@import "../../../../../assets/styles/variables";
@import "../../../../../assets/styles/mixins";

.audio-volume {
  display: none;

  @include tablet {
    display: flex;
    align-items: center;
    margin-right: 0.5rem;
  }

  &__slider {
    -webkit-appearance: none;
    height: 5px;
    background: linear-gradient(90deg, $darkBlue 100%, $lightGray 100%);
    outline: none;
    border-radius: 1rem;

    &::-webkit-slider-thumb {
      -webkit-appearance: none;
      appearance: none;
      width: 1rem;
      height: 1rem;
      background: $darkBlue;
      border-radius: 50%;
      cursor: pointer;
    }

    &::-moz-range-thumb {
      -webkit-appearance: none;
      appearance: none;
      width: 1rem;
      height: 1rem;
      background: $darkBlue;
      border-radius: 50%;
      cursor: pointer;
    }
  }
}
