@import "../../../../assets/styles/variables";
@import "../../../../assets/styles/mixins";

.example-block-of-part {
  @include desktop {
    flex: 0.7 1;
  }

  &__title {
    color: $lightBlue;
    font-weight: bold;
    font-size: 1.5rem;
    text-align: left;
  }

  &__content {
    background-color: $white;
    padding: 1rem;
    margin-top: 1rem;
    @include desktop {
      display: flex;
    }
  }

  &__image {
    @include desktop {
      width: 100%;
      margin-right: 2rem;
    }

    img {
      max-width: 100%;
      max-height: 100%;
      margin-bottom: 1rem;

      @include desktop {
        margin-bottom: 0;
      }
    }
  }

  &__subtitle {
    margin: 0 0 1rem 0;
    font-size: 1rem;
    font-weight: bold;
  }

  &__example {
    ul {
      display: inline-flex;

      li {
        border: 1px solid $lightBlue;
        padding: 2px 8px;
        border-radius: 50%;
        font-weight: bold;

        &:nth-child(3n) {
          color: $white;
          background-color: $lightBlue;
        }

        &:not(:last-child) {
          margin-right: 12px;
        }
      }
    }

    p {
      margin-top: 1.8rem;
    }
  }
}
