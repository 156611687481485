@import '../../../assets/styles/variables';
@import '../../../assets/styles/mixins';

.intro-block {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: left;
  padding: 1.5rem 2rem;
  &__container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-bottom: 2rem;

    @include desktop{
      flex-direction: row;
      margin-bottom: 6rem;
      max-width: 85rem;
    }
  }
  &__logo {
    margin: 0;

    @include desktop {
      margin-right: 3.2rem;
    }
  }

  &__content {
    display: flex;
    flex-direction: column;
  }

  &__title {
    font-weight: bold;
    color: $darkBlue;
    margin: 2rem 0;
    @include desktop {
      font-size: 2rem;
      margin-top: 0;
    }
  }

  &__indication-container {
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    @include desktop {
      flex-direction: row;
    }
  }

  &__indication-numbers {
    display: flex;
    flex-direction: column;
    margin-right: 0;
    margin-bottom: 2rem;
    font-weight: bold;

    @include desktop {
      margin-bottom: 0;
      margin-right: 2.5rem;
      font-size: 1.2rem;
    }
  }

  &__indication-icon {
    &:first-child {
      margin-bottom: 1.5rem;
    }

    display: flex;
    align-items: center;
    color: $lightBlue;
    font-weight: bold;
  }

  &__indication-text {
    padding: 1rem;
    background-color: $backgroundBlue;
  }

  &__indication-title {
    color: $lightBlue;
    margin-top: 0;
    font-weight: bold;
    @include desktop {
      font-size: 1.2rem;
    }
  }

  &__indication-list {
    padding: 0 1rem;
    list-style: none;
    color: $gray;

    li {
      margin-bottom: 0.5rem;
      &::before {
        content: "\2022";
        color: $lightBlue;
        font-weight: bold;
        display: inline-block;
        width: 1em;
        margin-left: -1em;
      }
    }
  }

  &__indication-commercial-offer {
    font-weight: bold;
    color: $darkBlue;
    text-align: center;
    margin-bottom: 1rem;
    @include desktop {
      font-size: 1.2rem;
      padding: 0 8rem;
      margin-top: 0;
    }
  }

  &__button {
    width: 100%;
    text-align: center;
    @include desktop {
      width: 16rem;
    }
  }
}
