@import '../../../assets/styles/variables';

.loader {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 30px;

  &__dots {
    width: 6px;
    height: 6px;
    border-radius: 50%;
    animation: loader-animation 1s linear infinite alternate both;
  }
}

@keyframes loader-animation {
  0% {
    background-color: rgba($secondary-color, 0.2);
    box-shadow: -12px 0 0 0 rgba($secondary-color, 1), 12px 0 0 0 rgba($secondary-color, 0.2);
  }

  50% {
    background-color: rgba($secondary-color, 1);
    box-shadow: -12px 0 0 0 rgba($secondary-color, 0.2), 12px 0 0 0 rgba($secondary-color, 0.2);
  }

  100% {
    background-color: rgba($secondary-color, 0.2);
    box-shadow: -12px 0 0 0 rgba($secondary-color, 0.2), 12px 0 0 0 rgba($secondary-color, 1);
  }
}
