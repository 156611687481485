@mixin aspect-radio($width, $height) {
  position: relative;
  overflow: hidden;
}

@mixin tablet {
  @media (min-width: #{$small-breakpoint}) {
    @content;
  }
}

@mixin desktop {
  @media (min-width: #{$main-breakpoint}) {
    @content;
  }
}

