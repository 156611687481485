@import '../../../assets/styles/variables';
@import '../../../assets/styles/mixins';

.set-block {
  flex: 1;
  width: 100%;
  text-align: left;
  padding: 1.5rem 1rem 6rem;
  @include desktop {
    padding: 0;
  }

  &__title {
    font-weight: bold;
  }

  &__container {
    display: flex;
    flex-direction: column;
    border-top: 2px solid $darkBlue;
    margin-top: 1rem;
  }

  &__subtitle {
    align-self: flex-start;
    background-color: $darkBlue;
    color: $white;
    padding: 0.3rem;
    margin-bottom: 1rem;
    font-weight: bold;
  }

  &__content {
    display: flex;
    flex-direction: column;
    @include desktop {
      flex-direction: row;
    }
  }

  &__image {
    margin-bottom: 1rem;
    @include desktop {
      margin-right: 2rem;
    }

    img {
      max-width: 100%;
      @include desktop {
        max-height: 30rem;
      }
    }
  }

  &__questions {
    display: flex;
    flex-direction: column;
  }

  &__question-title {
    font-weight: bold;
    margin-bottom: 1.5rem;
  }
}
