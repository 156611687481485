@import '../../../assets/styles/mixins';
@import '../../../assets/styles/variables';

.error-block {
  margin: 0 auto;
  max-width: 1158px;
  font-weight: bold;

  @include desktop {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    align-items: center;
    padding-bottom: 70px;
  }

  &__title {
    font-style: italic;
    color: $secondary-color;
    font-size: 62px;
    line-height: 1.5;
    margin: 0;
  }
  &__content {
    text-align: center;
    padding-left: $mobile-side-margin;
    padding-right: $mobile-side-margin;

    @include desktop {
      text-align: left;
      padding-left: 0;
      padding-right: 0;
      flex: 0 0 25%;
    }
  }

  &__description {
    font-size: 24px;
    color: $green;
    margin-bottom: 30px;
  }

  &__status-code {
    font-size: 46vw;
    color: $white;
    text-shadow: 0 25px 60px rgba($black, 0.1);

    @include desktop {
      font-size: 386px;
      text-shadow: 0 50px 110px rgba($black, 0.1);
      padding: 0 4%;
    }
  }
}
