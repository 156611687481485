@import 'mixins';
@import 'variables';

* {
  &, &::before, &::after {
    box-sizing: inherit;
  }
}

html, body {
  height: 100%;
  margin: 0;
  padding: 0;
}

html {
  box-sizing: border-box;
}

body {
  font-family: 'Open Sans', arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  text-align: left;
  line-height: 1.4;
  overflow: hidden;
  overflow-y: auto;
  margin: 0;

  #root {
    height: 100%;
  }
}

a {
  color: inherit;
  text-decoration: none;
}

button {
  font-family: inherit;
  color: inherit;
  cursor: pointer;
  appearance: none;
  background: transparent;
}

a, button {
  &:focus {
    outline-offset: 1px;
    outline-color: $primary-color;
  }
}

p {
  margin: 0;
}

ul {
  margin: 0;
  padding: 0;
  list-style: none;
}
